import { Navigate } from 'react-router-dom';
import { useCallback, useEffect, FC, Suspense } from 'react';
import { useNavigate, useLocation } from 'react-router';

import * as authActions from 'actions/auth';

import AsyncLoadedComponent from 'components/AsyncLoadedComponent';

import { getSessionToken } from 'helpers/utils';

import API from 'constants/api';
import routes from 'constants/routes';

import useLoading from 'hooks/isLoading';
import { useProfile } from 'hooks/useCdeebee';
import useTypedDispatch from 'hooks/useTypedDispatch';

import { setRedirectValue } from 'helpers/dom';

import { EnumUserRole, ProfileDto } from 'models/objects';

interface IProps {
  children: React.ReactNode;
  permissionChecker?: (profile: ProfileDto) => boolean;
}

const PrivateRouter: FC<IProps> = ({ children, permissionChecker }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useTypedDispatch();

  const profile = useProfile();
  const isLoading = useLoading([API.userProfileRequestDto, API.userTokenDisposeRequestDto]);

  useEffect(() => {
    if (!getSessionToken()) {
      navigate(`${routes.login}${setRedirectValue(location)}`);
    }
  });

  const handleUser = useCallback(() => dispatch(authActions.userProfileRequest()), [dispatch]);

  useEffect(() => {
    if (getSessionToken()) {
      handleUser();
    }
  }, [handleUser]);

  if (isLoading || (!isLoading && !profile)) {
    return <AsyncLoadedComponent />;
  }

  if (permissionChecker && profile && !permissionChecker(profile)) {
    if (profile.userRole === EnumUserRole.administrator) {
      return <Navigate to={routes.summary} replace={true} />;
    }
    return <Navigate to={routes.index} />;
  }

  return <Suspense fallback={<AsyncLoadedComponent />}>{children}</Suspense>;
};

export default PrivateRouter;
