import { Dispatch } from 'redux';
import { cdeebeeTypes } from '@recats/cdeebee';

import API from 'constants/api';
import { EnumCssVars, EnumListName } from 'constants/enum';
import { IAction } from './types';

const checkListApi = [
  { api: API.campaignCopyRequestDto, list: EnumListName.campaignList },
  { api: API.campaignListStatsRequestDto, list: EnumListName.campaignList },
  { api: API.campaignDetailsListRequestDto, list: EnumListName.campaignList },
  { api: API.campaignChangeSetRequestDto, list: EnumListName.campaignList },
  { api: API.campaignStatsGroupingRequestDto, list: EnumListName.campaignList },
  { api: API.campaignUpdateRequestDto, list: EnumListName.campaignList },

  { api: API.creativeListRequestDto, list: EnumListName.creativeList },

  { api: API.reportTaskListRequestDto, list: EnumListName.reportTaskList },

  { api: API.productStatsListRequestDto, list: EnumListName.reklList },

  { api: API.mLearnTaskListRequestDto, list: EnumListName.mLearnTaskList },
];

const checkStyleProp = (prop: 'block'|'none') => {
  if (!import.meta.env.SSR) {
    if (prop === 'none' && !document.body.style.getPropertyValue(EnumCssVars.globalNoDisablePreloader)) {
      return true;
    }
    return document.body.style.getPropertyValue(EnumCssVars.globalNoDisablePreloader) === prop;
  }
  return false;
};

export default ({ getState }: { getState: any; dispatch: Dispatch }) => (next: any) => (action: IAction<unknown>) => {
  const result = next(action);

  // @ts-ignore
  if (action.type !== cdeebeeTypes.CDEEBEE_REQUESTMANAGER_SET || action.type !== cdeebeeTypes.CDEEBEE_REQUESTMANAGER_SHIFT) {
    const { cdeebee, requestManager } = getState();
    const apiList: string[] = requestManager.activeRequest.map((e: { api: string }) => e.api);

    checkListApi.forEach(element => {
      if (apiList.includes(element.api) && cdeebee[element.list] && checkStyleProp('none')) {
        document.body.style.setProperty(EnumCssVars.globalNoDisablePreloader, 'block');
      }
    });

    if (checkListApi.filter(q => apiList.includes(q.api)).length === 0 && checkStyleProp('block')) {
      document.body.style.setProperty(EnumCssVars.globalNoDisablePreloader, 'none');
    }
  }
  return result;
};
