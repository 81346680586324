import { thunk, ThunkDispatch } from 'redux-thunk';
import { applyMiddleware, legacy_createStore as createStore, StoreEnhancer, UnknownAction } from 'redux';
import { composeWithDevTools } from '@redux-devtools/extension';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory, createMemoryHistory } from 'history';

import internalError from 'middleware/internalError';
import notification from 'middleware/notification';
import checkApiVersion from 'middleware/checkApiVersion';
import checkNoDisabledLoader from 'middleware/checkNoDisabledLoader';
import useReklFiguresMiddleware from 'middleware/useReklFigures';

import { IStorage } from 'models/utils';

import reducers from './reducers';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: import.meta.env.SSR ? createMemoryHistory() : createBrowserHistory(),
});

function configureStore(initialState: object) {
  const cfg = applyMiddleware(
    routerMiddleware,
    thunk,
    checkApiVersion,
    internalError,
    checkNoDisabledLoader,
    notification,
    useReklFiguresMiddleware,
  );

  let apy: StoreEnhancer = cfg;

  if (import.meta.env.MODE === 'development') {
    const composeEnhancers = composeWithDevTools({
      maxAge: 20,
      features: {
        lock: false,
        export: false,
        test: false,
        reorder: false,
      },
    });
    apy = composeEnhancers(cfg);
  }

  return createStore(
    reducers(routerReducer),
    initialState,
    apy,
  );
}

const getState = () => {
  if (typeof window !== 'undefined' && (window as any)?.__PRELOADED_STATE__) {
    return (window as any).__PRELOADED_STATE__;
  }
  return {};
};

export const store = configureStore(getState());
export const history = createReduxHistory(store);

export type AppDispatch = typeof store.dispatch;
// export type ReduxState = ReturnType<typeof store.getState>;
// export type TypedDispatch = ThunkDispatch<ReduxState, any, UnknownAction>;
export type TypedDispatch = ThunkDispatch<IStorage, any, UnknownAction>;
