import * as Sentry from '@sentry/react';
import { EnumUserRole, SessionDto } from 'models/objects';

export const loggerCompanyUserSet = (sessionList: SessionDto[]) => {
  if (!sessionList) return;

  const user = {
    email: '',
    id: '',
    userRole: '',
    sub_email: '',
    sub_id: '',
    sub_userRole: '',
    ui_version: import.meta.env.REACT_APP_BUILD_VERSION,
  };

  const values = Object.values(sessionList);

  for (const session of values) {
    const { userRole, email, profileID } = session.profile;
    if (values.length === 2) {
      if (userRole === EnumUserRole.administrator) {
        user.userRole = userRole;
        user.email = email;
        user.id = profileID.toString();
      }
      user.sub_userRole = userRole;
      user.sub_email = email;
      user.sub_id = profileID.toString();
    } else {
      user.userRole = userRole;
      user.email = email;
      user.id = profileID.toString();
    }
  }

  Sentry.setUser(user);
};

export const loggerCaptureException = (error: Error | null, extra: object = {}, _name: string) => {
  Sentry.withScope(scope => {
    scope.setContext('app', { _name, ...extra });
    Sentry.captureException(error);
  });
};
