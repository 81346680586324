import { EnumUserPermissionDto, EnumUserRole, ProfileDto } from 'models/objects';

export const permissionToBoolean = (profile: ProfileDto, permission: EnumUserPermissionDto): boolean => profile?.permissionList.includes(permission);
export const userRoleToBoolean = (profile: ProfileDto, accessRoles: EnumUserRole[]): boolean => accessRoles.includes(profile?.userRole);

export interface IReturnPermission {
  markups: { menu: boolean; edit: boolean, routerList: boolean; create: boolean },
  campaigns: { create: boolean; copy: boolean, campaignSettingsView: boolean; reengagement: boolean },
  reports: { menu: boolean; routerList: boolean },
  advertisers: { menu: boolean; viewTableControls: boolean; delete: boolean; edit: boolean; shareAdvertiser: boolean; create: boolean; routerList: boolean; routerDetail: boolean; },
  reklBundles: { menu: boolean; },
  bundles: { menu: boolean; },
  creatives: { menu: boolean; copy: boolean; approveAction: boolean; fileAndAbTestingSettings: boolean; create: boolean; routerList: boolean; },
  segments: { view: boolean; upload: boolean; },
  product: { archived: boolean; edit: boolean; create: boolean; },
  user: { pretend: boolean; toggleActiveUser: boolean; showAmountInHeader: boolean; showColOrganizationInUserManagerTable: boolean; showPermissionControlOnCRUDUser: boolean; routerTransactionsHistory: boolean; routerPaymentOptions: boolean; routerManager: boolean },
  history: { routerList: boolean; },
  organization: { billingView: boolean; organizationView: boolean; organizationList: boolean; organizationCreate: boolean; organizationEdit: boolean; userManager: boolean; },
  campaignSettings: { includeNonPayableEvents: boolean; },
  models: { modelTasksPage: boolean; },
  trackingEdit: boolean;
  predictorEdit: boolean;
  resourceUpload: boolean;
  billing: { billingPage: boolean, creditForm: boolean, stripeForm: boolean }
  summary: { view: boolean }
}

export const permissionObjectChecker = (profile: ProfileDto): IReturnPermission => ({
  markups: {
    menu: permissionToBoolean(profile, EnumUserPermissionDto.MarkupEdit)
      && userRoleToBoolean(profile, [EnumUserRole.administrator]),
    edit: permissionToBoolean(profile, EnumUserPermissionDto.MarkupEdit)
      && userRoleToBoolean(profile, [EnumUserRole.administrator]),
    routerList: permissionToBoolean(profile, EnumUserPermissionDto.MarkupEdit)
      && userRoleToBoolean(profile, [EnumUserRole.administrator]),
    create: permissionToBoolean(profile, EnumUserPermissionDto.MarkupEdit)
      && userRoleToBoolean(profile, [EnumUserRole.administrator]),
  },

  campaigns: {
    create: permissionToBoolean(profile, EnumUserPermissionDto.CampaignCreate)
      && userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
    copy: permissionToBoolean(profile, EnumUserPermissionDto.Clone),
    campaignSettingsView: permissionToBoolean(profile, EnumUserPermissionDto.CampaignSettingsView),
    reengagement: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
  },

  reports: {
    menu: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
    routerList: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
  },

  advertisers: {
    menu: true,
    viewTableControls: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
    delete: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
    shareAdvertiser: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
    create: permissionToBoolean(profile, EnumUserPermissionDto.SubuserManagement),
    edit: permissionToBoolean(profile, EnumUserPermissionDto.AdvertiserEdit),
    routerList: true,
    routerDetail: true,
  },

  reklBundles: {
    menu: permissionToBoolean(profile, EnumUserPermissionDto.ProductEdit),
  },

  bundles: {
    menu: permissionToBoolean(profile, EnumUserPermissionDto.ProductEdit),
  },

  creatives: {
    menu: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
    routerList: true,
    copy: permissionToBoolean(profile, EnumUserPermissionDto.Clone),
    create: permissionToBoolean(profile, EnumUserPermissionDto.CreativeCreate),
    approveAction: userRoleToBoolean(profile, [EnumUserRole.administrator]) && permissionToBoolean(profile, EnumUserPermissionDto.CreativeApprove),
    fileAndAbTestingSettings: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
  },

  segments: {
    view: true,
    upload: permissionToBoolean(profile, EnumUserPermissionDto.SegmentUpload),
  },

  product: {
    archived: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
    edit: permissionToBoolean(profile, EnumUserPermissionDto.ProductEdit),
    create: permissionToBoolean(profile, EnumUserPermissionDto.ProductEdit),
  },

  user: {
    pretend: userRoleToBoolean(profile, [EnumUserRole.administrator]),
    toggleActiveUser: permissionToBoolean(profile, EnumUserPermissionDto.SubuserManagement),
    showAmountInHeader: permissionToBoolean(profile, EnumUserPermissionDto.SubuserManagement),
    showColOrganizationInUserManagerTable: userRoleToBoolean(profile, [EnumUserRole.administrator]),
    showPermissionControlOnCRUDUser: userRoleToBoolean(profile, [EnumUserRole.administrator]),
    routerTransactionsHistory: permissionToBoolean(profile, EnumUserPermissionDto.SubuserManagement),
    routerPaymentOptions: permissionToBoolean(profile, EnumUserPermissionDto.SubuserManagement),
    routerManager: permissionToBoolean(profile, EnumUserPermissionDto.SubuserManagement)
      || userRoleToBoolean(profile, [EnumUserRole.administrator]),
  },

  history: {
    routerList: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
  },

  billing: {
    creditForm: permissionToBoolean(profile, EnumUserPermissionDto.Billing) && userRoleToBoolean(profile, [EnumUserRole.administrator]),
    billingPage: permissionToBoolean(profile, EnumUserPermissionDto.Billing),
    stripeForm: permissionToBoolean(profile, EnumUserPermissionDto.Billing) && userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
  },

  organization: {
    billingView: permissionToBoolean(profile, EnumUserPermissionDto.Billing),

    organizationView: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]) && permissionToBoolean(profile, EnumUserPermissionDto.OrganizationManagement),
    organizationList: userRoleToBoolean(profile, [EnumUserRole.administrator]),
    organizationCreate: userRoleToBoolean(profile, [EnumUserRole.administrator]) && permissionToBoolean(profile, EnumUserPermissionDto.OrganizationManagement),
    organizationEdit: userRoleToBoolean(profile, [EnumUserRole.administrator]) && permissionToBoolean(profile, EnumUserPermissionDto.OrganizationManagement),

    userManager: permissionToBoolean(profile, EnumUserPermissionDto.OrganizationUserManagement),
  },

  campaignSettings: {
    includeNonPayableEvents: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator]),
  },

  models: {
    modelTasksPage: userRoleToBoolean(profile, [EnumUserRole.buyer, EnumUserRole.administrator])
  },

  summary: {
    view: userRoleToBoolean(profile, [EnumUserRole.administrator]),
  },

  trackingEdit: permissionToBoolean(profile, EnumUserPermissionDto.TrackingEdit),
  predictorEdit: permissionToBoolean(profile, EnumUserPermissionDto.PredictorEdit),
  resourceUpload: permissionToBoolean(profile, EnumUserPermissionDto.ResourceUpload),
});
