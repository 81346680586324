import { getUrlParam } from 'helpers/utils';

import { IUiGlobalStatsFilter, IUIModel } from 'models/ui';
import { ConversionSettingsDto, FilterElementOr } from 'models/objects';

import routes from 'constants/routes';
import { EnumFilterURLList } from 'constants/enum';

export const normalizeUrlFilter = {
  needJson: (key: EnumFilterURLList) => {
    return [
      EnumFilterURLList.statsFilter_campaignIDList,
      EnumFilterURLList.statsFilter_reklIDList,
      EnumFilterURLList.statsFilter_managerIDList,
      EnumFilterURLList.statsFilter_productList,
      EnumFilterURLList.statsGrouping_groupingKeyList,
      EnumFilterURLList.statsFilter_filterList,

      EnumFilterURLList.summary_reklIDList,
      EnumFilterURLList.summary_orgIDList,
      EnumFilterURLList.summary_exchangeList,
      EnumFilterURLList.summary_countryIDList,
    ].includes(key);
  },
  set: (key: EnumFilterURLList, value: string | boolean | any[] | null | number, prefix: '?' | '&' | '' = '&') => {
    if ((Array.isArray(value) && value.length) || value !== undefined) {
      const vl = normalizeUrlFilter.needJson(key) ? JSON.stringify(value) : value;
      return `${prefix}${key}=${vl}`;
    }

    return '';
  },
  get: (key: EnumFilterURLList, defaultValue: any) => {
    const param = decodeURIComponent(getUrlParam(key) || '');
    if (normalizeUrlFilter.needJson(key) && param) {
      try {
        return JSON.parse(param.replace(new RegExp('[”“«»]', 'g'), '"'));
      } catch (err) {
        console.log(err);
        return defaultValue;
      }
    }

    if ([EnumFilterURLList.statsFilter_campaignStates].includes(key)) {
      return param ? param.split(',') : defaultValue;
    }

    if (param === 'null') {
      return null;
    }

    return param || defaultValue;
  },
  getCampaignIDList: (fallbackKeys: EnumFilterURLList, defaultValue: number[]) => {
    if (!import.meta.env.SSR && window.location.pathname.startsWith(routes.campaignsDetail)) {
      const getIDList = decodeURIComponent(window.location.pathname).match(/\[([^\]]+)/);
      if (getIDList && getIDList.length >= 2 && getIDList[1]) {
        return getIDList[1].split(',').map(Number);
      }
      return defaultValue;
    }
    return normalizeUrlFilter.get(fallbackKeys, defaultValue);
  }
};

export const checkBoolean = (value: string | boolean) => {
  if (typeof value === 'boolean') {
    return value;
  }
  return value === 'true';
};

export const checkNumber = (value: string): number | null => {
  if (value === 'null' || Number.isNaN(+value) || value === null) {
    return null;
  }
  return +value;
};

export const omitEmptyFilterList = (filterList: FilterElementOr[]) => {
  return filterList.filter(q => q.elementAndList.filter(d => d.value && d.name).length);
};

interface IReturnNormalize extends IUiGlobalStatsFilter {
  conversionSettings: ConversionSettingsDto;
  includeNonPayableEvents: boolean;
  onlyUniqEvents: boolean;
}
export const defaultNormalizeGlobalStatsFilter = (props: IUIModel, location: string): IReturnNormalize => {
  const { fromDate, toDate, statsFilter, statsGroup, ...others } = props.globalStatsFilter;
  const isNeedDefineGroupKeyList = location.startsWith(routes.campaignsDetail);

  return {
    conversionSettings: props.conversionSettings,
    fromDate,
    toDate,
    includeNonPayableEvents: props.campaignSettings.includeNonPayableEvents,
    onlyUniqEvents: props.campaignSettings.onlyUniqEvents,
    statsFilter: {
      ...statsFilter,
      filterList: omitEmptyFilterList(statsFilter.filterList),
    },
    statsGroup: {
      ...statsGroup,
      groupingKeyList: isNeedDefineGroupKeyList ? statsGroup.groupingKeyList : [],
    },
    ...others,
  };
};
