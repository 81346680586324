import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { isDarkTheme, BideaseContext, contextController } from '@bidease/ui-kit';

import Routers from './routers';

import 'style/index.scss';

import { history, store } from './store';

if (!import.meta.env.SSR && typeof window !== 'undefined') {
  if (isDarkTheme()) {
    document.documentElement.classList.add('dark');
  }
  if (!import.meta.env.DEV) {
    Sentry.init({ dsn: 'https://1b9a1565cafe427d93c91193578e7712@o286912.ingest.us.sentry.io/1520273' });

    Sentry.setContext('app', {
      version: import.meta.env.REACT_APP_BUILD_VERSION,
      mode: import.meta.env.MODE,
      lsVersion: localStorage.getItem('version'),
    });
  }
}

export default function App() {
  const [state, action] = contextController();
  return (
    <Provider store={store}>
      <BideaseContext.Provider value={{ state, action }}>
        <Router history={history}>
          <HelmetProvider>
            <Routers />
          </HelmetProvider>
        </Router>
      </BideaseContext.Provider>
    </Provider>
  );
}
