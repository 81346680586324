import { EnumMenuProductState } from 'models/product';

import routes from 'constants/routes';

export const productLink = {
  edit: (productID: number, campaignID?: undefined | string | number, path?: EnumMenuProductState) => {
    const refCampaignID = campaignID ? `?ref_campaign_id=${campaignID}` : '';
    return `${routes.productEdit}/${productID}/${path || EnumMenuProductState.basicInfo}${refCampaignID}`;
  },
  history: (product: number) => `${routes.history}/product/${product}`,
  create: (path?: EnumMenuProductState): string => `${routes.productCreate}/${path || EnumMenuProductState.basicInfo}`,
};
