import { Dispatch } from 'redux';
import { push } from 'redux-first-history';
import { cdeebeeMergeStrategy } from '@recats/cdeebee';

import API from 'constants/api';
import { defaultMergeListStrategy, EnumListName } from 'constants/enum';

import {
  deepDifferenceMerge,
  normalizeAndReplaceRawResponse,
  normalizeAndGetExtension,
  normalizeWithShitCases,
} from 'normalize/helpers';
import { campaignStatsRequest } from 'normalize/campaigns';
import { defaultNormalizeGlobalStatsFilter } from 'normalize/ui';

import { EnumSelectionType, IChangeSetRequest } from 'models/campaigns';
import { IUiGlobalStatsFilter } from 'models/ui';
import {
  CampaignDto,
  CampaignDetailsListRequestDto,
  CampaignChangeSetRequestDto,
  CampaignTitlesListRequestDto,
  EnumChangeSetReturnSet,
  CleanServerResponse,
  CampaignCopyApiRequestDto
} from 'models/objects';

import { toggleValueInArray, OmitDefaultRequestValue, IInObjectDto } from 'helpers/utils';
import { IGetState } from 'models/utils';

import * as uiActions from 'actions/ui';

import { campaignLink, getOrderingCampaignData } from 'shorthands/campaigns';

import { request } from './utils';
import { ICampaignValidation } from 'validation/campaigns';

export function campaignCreateRequest(cmp: ICampaignValidation) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      data: { campaign: cmp },
      api: API.campaignCreateRequestDto,
      postUpdate: (response: CleanServerResponse) => dispatch(push(campaignLink.edit(response.campaignList.data[0].campaignID)) as any),
    })(dispatch, getState);
  };
}

export function campaignListStatsRequest(postUpdate?: (raw: CleanServerResponse) => void) {
  return (dispatch: Dispatch, getState: IGetState) => (
    request({
      api: API.campaignListStatsRequestDto,
      data: defaultNormalizeGlobalStatsFilter(getState().ui, getState().router.location!.pathname),
      preUpdate: (response: CleanServerResponse) => {
        if (response.campaignList && response.campaignList.data) {
          const { selectionIDList } = getState().ui.globalStatsFilter;
          if (selectionIDList.length) {
            const responseIDList = response.campaignList.data.map((q: CampaignDto) => q.campaignID);
            const selectionList = selectionIDList.filter((q: number) => responseIDList.includes(q));
            uiActions.onChangeValue([{ key: ['globalStatsFilter', 'selectionIDList'], value: selectionList }])(dispatch);
          }
        }
      },
      postUpdate,
      normalize: normalizeAndGetExtension,
      mergeListStrategy: {
        ...defaultMergeListStrategy,
        [EnumListName.campaignList]: cdeebeeMergeStrategy.replace,
      },
    })(dispatch, getState)
  );
}

export function campaignStatsDetailedRequest(campaignIDList: number[], postUpdate?: (raw: CleanServerResponse) => void) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      api: API.campaignDetailsListRequestDto,
      data: ({ ...campaignStatsRequest(campaignIDList, getState()), campaignIDList } as OmitDefaultRequestValue<CampaignDetailsListRequestDto>),
      normalize: normalizeAndReplaceRawResponse,
      preUpdate: postUpdate,
    })(dispatch, getState);
  };
}

export function campaignStatsGroupingRequest(campaignIDList: number[], postUpdate?: (rawResponseDto: CleanServerResponse) => void) {
  return (dispatch: Dispatch, getState: IGetState) => (
    request({
      api: API.campaignStatsGroupingRequestDto,
      data: campaignStatsRequest(campaignIDList, getState()),
      normalize: normalizeAndReplaceRawResponse,
      postUpdate,
    })(dispatch, getState)
  );
}

export function campaignUpdateRequest(campaign: ICampaignValidation | CampaignDto) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      data: { campaign },
      api: API.campaignUpdateRequestDto,
      normalize: normalizeWithShitCases,
    })(dispatch, getState);
  };
}

export function campaignCopyRequest(campaignID: number) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      data: { campaignID } as OmitDefaultRequestValue<CampaignCopyApiRequestDto>,
      api: API.campaignCopyRequestDto,
      postUpdate: (response: CleanServerResponse) => {
        window.open(`${window.location.origin}${campaignLink.edit(response.campaignList.data[0].campaignID)}`, '_blank');
      },
    })(dispatch, getState);
  };
}

export function onMassSelection(selection: EnumSelectionType | number) {
  return (dispatch: Dispatch, getState: IGetState) => {
    if (selection === EnumSelectionType.all) {
      interface IGetStateMassSelection {
        cdeebee: { campaignList: IInObjectDto<CampaignDto> };
        ui: { globalStatsFilter: IUiGlobalStatsFilter };
      }
      const { cdeebee: { campaignList }, ui: { globalStatsFilter } }: IGetStateMassSelection = getState();
      const { listsOrdering, nameContains } = globalStatsFilter;
      const allowSelection = getOrderingCampaignData(campaignList, nameContains, listsOrdering)
        .map((campaign: CampaignDto) => campaign.campaignID);
      uiActions.onChangeValue([{ key: ['globalStatsFilter', 'selectionIDList'], value: allowSelection }])(dispatch);
    } else if (selection === EnumSelectionType.reset) {
      uiActions.onChangeValue([{ key: ['globalStatsFilter', 'selectionIDList'], value: [] }])(dispatch);
    } else {
      const { globalStatsFilter: { selectionIDList } } = getState().ui;
      uiActions.onChangeValue(
        [
          { key: ['globalStatsFilter', 'selectionIDList'], value: toggleValueInArray(selectionIDList, selection) },
        ]
      )(dispatch);
    }
  };
}

export function campaignChangeSetRequest(changeSetList: IChangeSetRequest[]) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      data: ({ changeSetList, changeSetReturnSet: EnumChangeSetReturnSet.changed } as OmitDefaultRequestValue<CampaignChangeSetRequestDto>),
      api: API.campaignChangeSetRequestDto,
      normalize: deepDifferenceMerge,
    })(dispatch, getState);
  };
}

export function campaignListTitlesRequest(count: number, listFilter?: { search?: string; campaignIDList?: number[] }) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      data: ({ listFilter, count } as OmitDefaultRequestValue<CampaignTitlesListRequestDto>),
      api: API.campaignTitlesListRequestDto,
    })(dispatch, getState);
  };
}

export function campaignStatsGroupRequest(campaignIDList: number[]) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      data: { ...campaignStatsRequest(campaignIDList, getState()) },
      api: API.campaignStatsGroupRequestDto,
    })(dispatch, getState);
  };
}

export function campaignStatsRejectGroupRequest(campaignIDList: number[]) {
  return (dispatch: Dispatch, getState: IGetState) => {
    const { statsGroup, statsOrdering, ...all } = campaignStatsRequest(campaignIDList, getState());
    request({
      data: {
        ...all,
        statsGroup: { ...statsGroup, groupingKeyList: [] },
        statsOrdering: { ...statsOrdering, orderingList: [] },
      },
      api: API.campaignStatsRejectGroupRequestDto,
    })(dispatch, getState);
  };
}
