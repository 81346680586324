import types from 'actions/types';

import { INotification } from 'models/notification';

const INITIAL_STATE = {
  que: [],
};

export default function(state: INotification = INITIAL_STATE, action: any) {
  const { type, payload } = action;

  switch (type) {
    case types.NOTIFICATION_PUT:
      return {
        que: [...state.que, payload],
      };
    case types.NOTIFICATION_DROP:
      return {
        que: state.que.filter(q => q.requestID !== payload.requestID),
      };
    default:
      return state;
  }
}
