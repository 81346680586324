import { Regexp } from 'constants/service';

import { history } from 'store';

import { getUrlParam } from 'helpers/utils';

interface IObj { search: string; }
export const setPaginationParams = (page: number, count?: number): IObj => {
  if (!import.meta.env.SSR) {
    const { search } = window.location;
    const pageString = `page=${page}&count=${count}`;

    if (getUrlParam('page') !== undefined) {
      return { search: search.replace(Regexp.pagination, pageString) };
    }
    if (search) {
      return { search: `${search}&${pageString}` };
    }
    return { search: pageString };
  }
  return { search: '' };
};

export const getAspectRatio = (width: number, height: number): { width: number; height: number } => {
  if (+width || +height) {
    const gcd = (a: number, b: number): number => b ? gcd(b, a % b) : a;
    const divisor = gcd(+width, +height);
    return { width: width / divisor, height: height / divisor };
  }
  return { width: 0, height: 0 };
};

export const getRedirectValue = () => history.location.search.replace('?redirectTo=', '');

export const setRedirectValue = (location: any = window.location) => location.pathname !== '/' ? `?redirectTo=${location.pathname}${location.search}` : '';

export const preventForm = (fn: (event: React.FormEvent<HTMLFormElement>) => void) => (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();
  event.stopPropagation();
  fn(event);
};
