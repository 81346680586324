import { cdeebeeTypes } from '@recats/cdeebee';
import types from 'actions/types';
import { IAction } from './types';

let requestArray: string[] = [];
export default ({ dispatch }: any) => (next: any) => (action: IAction<any>) => {
  const result = next(action);
  if (action.type === cdeebeeTypes.CDEEBEE_REQUESTMANAGER_SET) {
    requestArray = [action.payload.requestID, ...requestArray].slice(0, 3);
  }
  if (action.type === cdeebeeTypes.CDEEBEE_INTERNAL_ERROR) {
    dispatch({ type: types.UI_INTERNAL_ERROR, payload: { show: true, requestArray } });
  }
  return result;
};
