import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';

import routes from 'constants/routes';

import { getSessionToken } from 'helpers/utils';
import AsyncLoadedComponent from '../AsyncLoadedComponent';

interface IProps {
  noRedirect?: boolean;
  children: any;
}
const BaseRouter: React.FC<IProps> = ({ children, noRedirect }) => {
  const hasSessionToken = getSessionToken() && !noRedirect;
  if (hasSessionToken) {
    return <Navigate to={routes.default} />;
  }
  return <Suspense fallback={<AsyncLoadedComponent />}>{children}</Suspense>;
};

export default BaseRouter;
