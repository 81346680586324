import { lazy } from 'react';

export const Auth = lazy(() => import('../pages/Auth'));
//

// [##START] campaign
export const CampaignIndex = lazy(() => import('../pages/Campaigns/Index'));
export const CampaignCreate = lazy(() => import('../pages/Campaigns/Create'));
export const CampaignDetail = lazy(() => import('../pages/Campaigns/Detail'));
export const CampaignEdit = lazy(() => import('../pages/Campaigns/Edit'));
// [##END] campaign

// [##START] creatives
export const CreativesIndex = lazy(() => import('../pages/Creatives/Index'));
export const CreativesCreate = lazy(() => import('../pages/Creatives/Create'));
export const CreativesEdit = lazy(() => import('../pages/Creatives/Edit'));
export const CreativePreview = lazy(() => import('../pages/Creatives/Preview'));
export const CreativeApprove = lazy(() => import('../pages/Creatives/Approve'));
// [##END] creatives

// [##START] user
export const UserSettings = lazy(() => import('../pages/User/Settings'));
// [##END] user

// [##START] UsersManager
export const UsersManager = lazy(() => import('../pages/UsersManager'));
// [##END] user

// [##START] Service
export const ServiceRestorePassword = lazy(() => import('../pages/Service/RestorePassword'));
export const ServiceNoResponseUser = lazy(() => import('../pages/Service/NoResponseUser'));
export const ServiceBillingStatus = lazy(() => import('../pages/Service/BillingStatus'));
export const NotFound = lazy(() => import('../pages/NotFound'));
// [##END] Service

// [##START] Advertisers
export const Advertisers = lazy(() => import('../pages/Advertisers/Index'));
export const AdvertiserCreate = lazy(() => import('../pages/Advertisers/Create'));
export const AdvertisersDetail = lazy(() => import('../pages/Advertisers/Detail'));
// [##END] Advertisers

// [##START] markups
export const Markups = lazy(() => import('../pages/Markup/Index'));
export const MarkupsEdit = lazy(() => import('../pages/Markup/Edit'));
export const MarkupsCreate = lazy(() => import('../pages/Markup/Create'));
// [##END] markups

// [##START] segments
export const Segments = lazy(() => import('../pages/Segments/Index'));
// [##END] segments

// [##START] reports
export const Reports = lazy(() => import('../pages/Reports/Index'));
// [##END] reports

// [##START] history
export const History = lazy(() => import('../pages/History'));
// [##END] history

// [##START] Models
export const ModelTasks = lazy(() => import('../pages/ModelTasks'));
// [##END] Models

// [##START] Organization
export const OrganizationIndex = lazy(() => import('../pages/Organizations/Index'));
export const OrganizationShow = lazy(() => import('../pages/Organizations/Show'));
export const OrganizationCreate = lazy(() => import('../pages/Organizations/Create'));
// [##END] Organization

// [##START] Product
export const ProductEdit = lazy(() => import('../pages/Product/Edit'));
export const ProductCreate = lazy(() => import('../pages/Product/Create'));
// [##END] Product

// [##START] Bundles
export const Bundles = lazy(() => import('../pages/Bundles/Index'));
export const BundleCreate = lazy(() => import('../pages/Bundles/Create'));
// [##END] Bundles

// [##START] ReklBundle
export const AdvertiserBundleIndex = lazy(() => import('../pages/AdvertiserBundle/Index'));
// [##END] ReklBundle

// [##START] Billing
export const BillingIndex = lazy(() => import('../pages/Billing/Index'));
// [##END] Billing

// [##START] Admin Summary
export const SummaryIndex = lazy(() => import('../pages/Summary'));
// [##END] Summary
