import { Dispatch } from 'redux';
import Cookies from 'js-cookie';
import { cdeebeeMergeStrategy, cdeebeeTypes } from '@recats/cdeebee';
import { fmtNumber, NumberFormat } from '@bidease/ui-kit';
import { push } from 'redux-first-history';

import API from 'constants/api';
import routes from 'constants/routes';
import { defaultMergeListStrategy, EnumListName } from 'constants/enum';

import { EnumResponseStatus } from 'constants/errors';

import { setUserTimezone } from 'helpers/timezone';
import { loggerCompanyUserSet } from 'helpers/logger';
import { userRoleToBoolean } from 'helpers/permission';
import { getRedirectValue, setRedirectValue } from 'helpers/dom';
import { getActiveUISession, getAdminSessionToken, getFirstElement, OmitDefaultRequestValue } from 'helpers/utils';
import { VerifySessionRequestDto, CleanServerResponse, EnumSessionStatus, EnumUserRole, SessionDto, UserRemindPasswordRequestDto } from 'models/objects';
import { IGetState } from 'models/utils';

import { dictionaryListRequest, IInitialCdeebee, initialCdeebee, request, setSessionToken } from './utils';

import { ILogin } from 'validation/auth';

const authFn = (response: CleanServerResponse, redirectUrl: string, callback?: () => void) => (dispatch: Dispatch) => {
  const { sessionList } = response;
  if (sessionList && sessionList.data) {
    const { profile, status, sessionToken, expiresAt } = getFirstElement<SessionDto>(sessionList.data);
    if (status === EnumSessionStatus.active) {
      setSessionToken(sessionToken, expiresAt);

      if (!profile) {
        throw new Error('profile is empty');
      }

      if (userRoleToBoolean(profile, [EnumUserRole.administrator])) {
        dispatch(push(redirectUrl || routes.summary) as any);
      } else {
        dispatch(push(redirectUrl || routes.default) as any);
      }

      if (callback) {
        callback();
      }
    }
  } else {
    dispatch(push(redirectUrl || routes.default) as any);
  }
};

export const actionResetAndExit = () => {
  Cookies.remove('sessionToken');
  Cookies.remove('adminSessionToken');
  (initialCdeebee as IInitialCdeebee).requestObject.data.sessionToken = undefined;
  (initialCdeebee as IInitialCdeebee).requestObject.data.adminSessionToken = undefined;

  window.location.href = routes.login;
};

export function onExitUser() {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      api: API.userTokenDisposeRequestDto,
      postUpdate: actionResetAndExit,
      preError: actionResetAndExit,
    })(dispatch, getState);
  };
}

export function userAuthRequest(data: ILogin) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      data,
      api: API.userAuthRequestDto,
      preUpdate: setUserTimezone,
      postUpdate: (response: CleanServerResponse) => (
        // the same method as in userSessionVerifyRequest
        authFn(
          response,
          getRedirectValue(),
          () => dictionaryListRequest()(dispatch, getState)
        )(dispatch)
      ),
    })(dispatch, getState);
  };
}

export function userSessionVerifyRequest(code: string, sessionToken: string) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      data: { code, sessionToken } as OmitDefaultRequestValue<VerifySessionRequestDto>,
      api: API.userSessionVerifyRequestDto,
      postUpdate: (response: CleanServerResponse) => (
        // The same method as in userAuthRequest
        authFn(
          response,
          getRedirectValue(),
          () => dictionaryListRequest()(dispatch, getState)
        )(dispatch)
      ),
    })(dispatch, getState);
  };
}

export function userRemindPasswordRequest(email: string) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      data: { email } as OmitDefaultRequestValue<UserRemindPasswordRequestDto>,
      api: API.userRemindPasswordRequestDto,
    })(dispatch, getState);
  };
}

export function userProfileRequest(successFn?: () => void) {
  return (dispatch: Dispatch, getState: IGetState) => (
    request({
      api: API.userProfileRequestDto,
      requestCancel: false,
      preUpdate: setUserTimezone,
      preError: (response: CleanServerResponse) => {
        if (response.responseStatus === EnumResponseStatus.AuthError) {
          dispatch({ type: cdeebeeTypes.CDEEBEEE_DROP });
          actionResetAndExit();
        } else {
          if (!/\?redirectTo/.test(getState().router.location!.search)) {
            const value = setRedirectValue();
            dispatch(push(`${routes.serviceNoUser}/${response.responseStatus}${value}`) as any);
          }
        }
      },
      postUpdate: (payload: CleanServerResponse) => {
        loggerCompanyUserSet(payload.sessionList.data);

        const session = getActiveUISession(payload.sessionList.data);
        const org = payload.organizationList.data.find(q => q.organizationID === session.profile.organizationID);
        if (org?.currency) {
          const val: Record<string, NumberFormat> = { 'USD': 'en-US', 'RUB': 'ru-RU' };
          fmtNumber._setLanguage(val[org.currency]);
        }
        if (session) {
          setSessionToken(session.sessionToken, session.expiresAt);
        }

        if (successFn) {
          successFn();
        }

        dictionaryListRequest()(dispatch, getState);
      },
      mergeListStrategy: {
        ...defaultMergeListStrategy,
        [EnumListName.userList]: cdeebeeMergeStrategy.replace,
      },
    })(dispatch, getState)
  );
}

export function userTokenDisposeRequest() {
  return (dispatch: Dispatch, getState: IGetState) => {
    const adminSessionToken = getAdminSessionToken();
    request({
      api: API.userTokenDisposeRequestDto,
      preUpdate: () => {
        if (adminSessionToken) {
          Cookies.remove('adminSessionToken');
          Cookies.set('sessionToken', adminSessionToken, { expires: 365 });
          (initialCdeebee as IInitialCdeebee).requestObject.data.sessionToken = adminSessionToken;
          (initialCdeebee as IInitialCdeebee).requestObject.data.adminSessionToken = undefined;
          userProfileRequest()(dispatch, getState);
        }
      },
    })(dispatch, getState);
  };
}
