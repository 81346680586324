import * as ReactDOM from 'react-dom/client';
import App from './App';

import * as serviceWorker from './serviceWorker';

const container = document.getElementById('bidease') as HTMLElement;
const root = ReactDOM.createRoot(container);
root.render(<App />);

if (serviceWorker) {
  serviceWorker.unregister();
}
