import { cdeebeeValueList } from '@recats/cdeebee';
import {
  CampaignStatsGroupDto,
  CampaignStatsFilterDto,
  CampaignStatsOrderingDto,
  ConversionSettingsDto,
} from 'models/objects';
import { onChangeValueList } from 'models/utils';

import { EnumColumnSettings } from 'reducers/ui';

export interface IUiActions {
  onFrequencyRequest: (frequencyType: FrequencyType) => void;
  onChangeValue: onChangeValueList;

  customizeGlobalStatsURL: (valueList: cdeebeeValueList[]) => any;
  setGlobalStatsFilterURI: () => void;
  getGlobalStatsFilterURI: () => any;
}

export type FrequencyUpdate = null | number;

export enum FrequencyType {
  frequencyList = 'frequencyList',
  frequencyDetail = 'frequencyDetail',
  frequencyAdvertisers = 'frequencyAdvertisers',
}

export interface IUiGlobalStatsFilter {
  fromDate: string;
  toDate: string;

  useReklFigures: boolean;

  selectionIDList: number[]; // ID кампаний
  selectionIDGroupList: number[]; // ID rows

  nameContains: string;

  listsOrdering: CampaignStatsOrderingDto;
  statsGroup: CampaignStatsGroupDto;
  statsFilter: CampaignStatsFilterDto;
  statsOrdering: CampaignStatsOrderingDto;
}

export interface IUICampaignSettings {
  includeNonPayableEvents: boolean;
  onlyUniqEvents: boolean;
  graphOpen: boolean;
}

export interface IUIInternalError { show: boolean; requestArray: string[] }

export interface IUIModel {
  internalError: IUIInternalError;
  columnSettings: typeof EnumColumnSettings;
  globalStatsFilter: IUiGlobalStatsFilter;
  frequencyAutoUpdate: {
    frequencyDetail: FrequencyUpdate;
    frequencyList: FrequencyUpdate;
    frequencyAdvertisers: FrequencyUpdate;
  };
  conversionSettings: ConversionSettingsDto;
  campaignSettings: IUICampaignSettings;
}
