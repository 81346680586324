import Cookies from 'js-cookie';
import { cdeebeeTypes } from '@recats/cdeebee';
import { resetWindowCache } from 'helpers/utils';

import { IAction } from './types';

export default ({ getState }: any) => (next: any) => async (action: IAction<unknown>) => {
  const result = next(action);

  if (action.type === cdeebeeTypes.CDEEBEEE_UPDATE) {
    if (action.payload?.cleanResponse) {
      const { apiVersion, expectedUiVersion } = action.payload.cleanResponse;

      if (expectedUiVersion && expectedUiVersion > import.meta.env.REACT_APP_BUILD_VERSION) {
        await resetWindowCache(false);
      }

      if (!Cookies.get('apiVersion')) {
        Cookies.set('apiVersion', apiVersion);
      } else {
        if (apiVersion !== getState().ui.apiVersion || apiVersion !== Cookies.get('apiVersion')) {
          Cookies.set('apiVersion', apiVersion);
          await resetWindowCache(true);
        }
      }
    }
  }
  return result;
};
