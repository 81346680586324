import { cdeebeeHelpers } from '@recats/cdeebee';

import types from 'actions/types';

import * as PretendModel from 'models/pretend';

export const INITIAL_STATE: PretendModel.IPretendReducer = {
  form: {
    reklID: undefined,
    organizationID: undefined,
    userID: undefined,
    campaignID: undefined,
  }
};

export default function(state: PretendModel.IPretendReducer = INITIAL_STATE, action: any) {
  const { type, payload } = action;

  switch (type) {
    case types.PRETEND_CHANGE_VALUE:
      return cdeebeeHelpers.batchingUpdate(state, payload.valueList, payload.preBatchingPath);
    case types.PRETEND_RESET_TO_INITIAL:
      return INITIAL_STATE;
    default:
      return state;
  }
}
