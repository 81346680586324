export const Regexp = {
  ecpa: /^ecpa[1-6]$/,
  goal: /^goal[1-6]$/,
  campaignID: /\[([\d,]+)]/,
  pagination: /page=([^&]+)&count=([^&]+)/,
  locationSearch: /([?&])/,
  inputSplitDotAndSemicolon: /;\s*|\n/,
  email: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
  filterList: {
    date: /\d{2}\/\d{2}\/\d{4}$/,
    float: /^[\d\\.-]+$/,
    int: /^[\d]+$/,
    string: /[\S ]+/,
    booleanToNumber: /[1|0]$/,

    dateIn: /^\d{2}\/\d{2}\/\d{4}(;\w*\d{2}\/\d{2}\/\d{4})*$/,
    floatIn: /^[\d\\.-]+(;\w*[\d\\.-]+)*$/,
    intIn: /^[\d]+(;\w*[\d]+)*$/,
    stringIn: /[\S ]+(;\w*[\S ]+)*/,
    booleanToNumberIn: /[1|0]+(;\w*[1|0]+)*/,
  },
  variableListValidator: {
    positiveNumber: /^\d+$/,
    boolean: /^(true|false)$/,
    mimeType: /^(image|video)\/\w+$/,
    duration: /^\d|([0-5]\d)+$/,
    closePosition: /^(top-left|top-right|bottom-left|bottom-right)$/,
    no: /^.*$/,
    No: /^.*$/,
  },
  appStoreLink: /^\d+$/,

  campaignIDList: /^\d+(,\d+)*$/,
  defaultDate: /\d{2}\/\d{2}\/\d{4}$|\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/,

  validations: {
    ifaSymbol: /^[0-9a-f]+$/g,
    targetingContainerState: /whitelist|notSpecified|blacklist/,
  },
};
