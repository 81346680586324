import { OmitDefaultRequestValue } from 'helpers/utils';

import { CampaignStatsRequestDto } from 'models/objects';
import { defaultNormalizeGlobalStatsFilter } from './ui';
import { IStorage } from 'models/utils';

export const campaignStatsRequest = (campaignIDList: number[], state: IStorage): Omit<OmitDefaultRequestValue<CampaignStatsRequestDto>, 'count'> => {
  const params = defaultNormalizeGlobalStatsFilter(state.ui, state.router.location!.pathname);
  return { ...params, statsFilter: { ...params.statsFilter, campaignIDList } };
};
