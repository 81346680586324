import { fmtDate } from '@bidease/ui-kit';
import { CleanServerResponse, EnumUserRole, SessionDto, UserDto } from 'models/objects';

import { getFirstElement } from './utils';

export const updateUserTimezone = (response: CleanServerResponse) => {
  if (response && response.userList && response.userList.data.length) {
    const user = getFirstElement<UserDto>(response.userList.data);
    if (user) {
      if (user.timeZone !== fmtDate._getOptions().timeZone && !import.meta.env.SSR) {
        fmtDate._setFormat({ format: 'en-US', timeZone: user.timeZone });
        window.location.reload();
      }
    }
  }
};

export const setUserTimezone = (payload: CleanServerResponse) => {
  if (payload?.sessionList && payload?.sessionList?.data?.length) {
    if (payload.sessionList.data.length === 2) {
      const user = payload.sessionList.data.find((item: SessionDto) => item.profile?.userRole !== EnumUserRole.administrator);
      if (user) {
        fmtDate._setFormat({ format: 'en-US', timeZone: user.profile.timeZone });
      }
    } else {
      const user = getFirstElement<SessionDto>(payload.sessionList.data);
      if (user) {
        fmtDate._setFormat({ format: 'en-US', timeZone: user.profile.timeZone });
      }
    }
  }
};
