import { Dispatch } from 'redux';

import { INotificationQue } from 'models/notification';

import types from './types';

export function createNotification(payload: INotificationQue) {
  return (dispatch: Dispatch) => {
    dispatch({ type: types.NOTIFICATION_PUT, payload });
  };
}
export function dropNotification(requestID: string) {
  return (dispatch: Dispatch) => {
    dispatch({ type: types.NOTIFICATION_DROP, payload: { requestID } });
  };
}
