import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { EnumColumnDataTableTypeList } from 'models/objects';
import { IStorage } from 'models/utils';

import * as uiActions from 'actions/ui';

import useTypedDispatch from 'hooks/useTypedDispatch';

import { toggleValueInArray } from 'helpers/utils';

export const EnumColumnDataTypeListToReduxStore = {
  [EnumColumnDataTableTypeList.campaignList]: 'selectionIDList',
  [EnumColumnDataTableTypeList.campaignDetails]: 'selectionIDGroupList',

  // NOTE: yet not used
  [EnumColumnDataTableTypeList.advertiserList]: '',
};

const useTableMassCheckboxSelection = (dataType: EnumColumnDataTableTypeList): (campaignID: number) => void => {
  const dispatch = useTypedDispatch();
  const refClicked = useRef(false);
  const prevSelectedID = useRef(0);
  const selection = useSelector<IStorage, number[]>(({ ui: { globalStatsFilter } }) => {
    if (dataType === EnumColumnDataTableTypeList.campaignList) {
      return globalStatsFilter.selectionIDList;
    } else if (dataType === EnumColumnDataTableTypeList.campaignDetails) {
      return globalStatsFilter.selectionIDGroupList;
    }
    return [];
  });

  const onKeydown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Shift') {
      refClicked.current = true;
    }
  }, []);

  const onKeyUp = useCallback(() => {
    if (refClicked.current) {
      refClicked.current = false;
    }
  }, []);

  const onSelectstart = useCallback((event: Event) => {
    if (refClicked.current) {
      event.preventDefault();
    }
  }, [refClicked]);

  const onSelect = useCallback((selectionId: number) => {
    if (refClicked.current) {
      const first = selection[selection?.length - 1];
      dispatch(uiActions.onShiftSelectionList(first, selectionId, prevSelectedID.current, dataType));
    } else {
      dispatch(uiActions.onChangeValue([{ key: ['globalStatsFilter', EnumColumnDataTypeListToReduxStore[dataType]], value: toggleValueInArray(selection, selectionId) }]));
    }
    prevSelectedID.current = selectionId;
  }, [dispatch, selection, dataType]);

  useEffect(() => {
    window.addEventListener('keydown', onKeydown);
    window.addEventListener('keyup', onKeyUp);
    window.addEventListener('selectstart', onSelectstart);
    return () => {
      window.removeEventListener('selectstart', onSelectstart);
      window.removeEventListener('keydown', onKeydown);
      window.removeEventListener('keyup', onKeyUp);
    };
  });

  return onSelect;
};

export default useTableMassCheckboxSelection;
