import { push } from 'redux-first-history';
import { cdeebeeMergeStrategy } from '@recats/cdeebee';
import { Dispatch } from 'redux';

import { deepFullMerge, normalizeAndGetExtension } from 'normalize/helpers';
import { defaultNormalizeGlobalStatsFilter } from 'normalize/ui';

import {
  CleanServerResponse,
  EnumUserRole,
  ProductCopyRequestDto,
  ProductDto,
  ProductListRequestDto,
  ProductUpdateRequestDto,
} from 'models/objects';

import { productLink } from 'shorthands/products';

import { IBasicProductValidation } from 'validation/product';

import API from 'constants/api';
import { defaultMergeListStrategy, EnumListName } from 'constants/enum';

import { IGetState } from 'models/utils';

import { getActiveUISession, OmitDefaultRequestValue } from 'helpers/utils';

import { request } from './utils';

export function productUpdateRequest(product: ProductDto) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      api: API.productUpdateRequestDto,
      data: { product } as OmitDefaultRequestValue<ProductUpdateRequestDto>,
    })(dispatch, getState);
  };
}

export function productUpdateObject(product: ProductDto, fn?: (productID: number) => void) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      api: API.productUpdateRequestDto,
      data: { product },
      normalize: deepFullMerge([EnumListName.reklList]),
      postUpdate: (response: CleanServerResponse) => fn && fn(response.productList.data[0].productID),
    })(dispatch, getState);
  };
}

export function productStatsListRequest(fn?: () => void) {
  return (dispatch: Dispatch, getState: IGetState) => {
    const filters = defaultNormalizeGlobalStatsFilter(getState().ui, getState().router.location!.pathname);
    const session = getActiveUISession(getState().cdeebee.sessionList);

    request({
      api: API.productStatsListRequestDto,
      data: {
        ...filters,
        statsFilter: {
          ...filters.statsFilter,
          onlyFavoriteRekls: session && session.profile.userRole === EnumUserRole.buyer ? filters.statsFilter.onlyFavoriteRekls : false,
        }
      },
      normalize: normalizeAndGetExtension,
      mergeListStrategy: {
        ...defaultMergeListStrategy,
        [EnumListName.productList]: cdeebeeMergeStrategy.replace,
        [EnumListName.reklList]: cdeebeeMergeStrategy.replace,
      },
      postUpdate: () => fn && fn(),
    })(dispatch, getState);
  };
}

export function productListRequest(data: { productIDList: number[], reklBundleIDList: number[] }) {
  return (dispatch: Dispatch, getState: IGetState) => {
    request({
      api: API.productListRequestDto,
      data: data as OmitDefaultRequestValue<ProductListRequestDto>,
    })(dispatch, getState);
  };
}

export function productCreateRequest(product: IBasicProductValidation) {
  return (dispatch: Dispatch, getState: any) => {
    request({
      api: API.productCreateRequestDto,
      data: { product },
      postUpdate: (response: CleanServerResponse) => dispatch(push(productLink.edit(response.productList.data[0].productID)) as any),
    })(dispatch, getState);
  };
}

export function productCopy(productID: number, reklBundleID: number) {
  return (dispatch: Dispatch, getState: any) => {
    request({
      api: API.productCopyRequestDto,
      data: { productID, reklBundleID } as OmitDefaultRequestValue<ProductCopyRequestDto>,
      postUpdate: (response: CleanServerResponse) => (
        window.open(`${window.location.origin}${productLink.edit(response.productList.data[0].productID)}`, '_blank'
        )
      ),
    })(dispatch, getState);
  };
}
