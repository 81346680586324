export default {
  CREATIVES_CHANGE_VALUE: '@CREATIVES/CHANGE_VALUE',
  CREATIVES_RESET_TO_INITIAL: '@CREATIVES/RESET_TO_INITIAL',

  SUMMARY_CHANGE_VALUE: '@SUMMARY/CHANGE_VALUE',

  PRETEND_CHANGE_VALUE: '@PRETEND/CHANGE_VALUE',
  PRETEND_RESET_TO_INITIAL: '@PRETEND/RESET_TO_INITIAL',

  UI_CHANGE_VALUE: '@UI/CHANGE_VALUE',
  UI_INTERNAL_ERROR: '@UI/INTERNAL_ERROR',

  NOTIFICATION_PUT: '@NOTIFICATION/PUT',
  NOTIFICATION_DROP: '@NOTIFICATION/DROP',
};
