export default {
  notFound: '/404',

  default: '/advertisers',
  index: '/advertisers',

  login: '/login',

  campaigns: '/campaigns',
  campaignsCreate: '/campaigns/create',
  campaignsDetail: '/campaigns/detail',
  campaignsEdit: '/campaigns/edit',

  advertisers: '/advertisers',
  advertiser: '/advertiser',
  advertiserCreate: '/advertiser/create',

  userSettings: '/user/settings',

  usersManager: '/users-manager',

  serviceConfirmPassword: '/service/confirm/password/:token',
  serviceNoUser: '/service/no-user',
  billingStatus: '/service/billing/:status',

  creatives: '/creatives',
  creativesApprove: '/creatives-approve',
  creativesCreate: '/creative/create',
  creativesEdit: '/creative/edit',
  creativesPreview: '/creative/preview',

  markups: '/markups',
  markupsEdit: '/markup/edit',
  markupsCreate: '/markup/create',

  segments: '/segments',

  reports: '/reports',

  history: '/history',

  organizations: '/organizations',
  organizationsView: '/organization/view',
  organizationsEdit: '/organization/edit',
  organizationCreate: '/organization/create',

  bundles: '/bundles',
  bundleCreate: '/bundle/create',

  advertiserBundle: '/advertiser-bundle',

  modelTasks: '/model-tasks',

  products: '/products',
  productCreate: '/product/create',
  productEdit: '/product/edit',

  billing: '/billing',

  summary: '/summary',

  googleMap: 'https://www.google.com/maps/place',
  appleStore: 'https://apps.apple.com/app/id',
  googleStore: 'https://play.google.com/store/apps/details?id=',
};
