import { memo, useEffect, useRef } from 'react';
import { Preloader } from '@bidease/ui-kit';

const AsyncLoaded = () => {
  const refContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refContainer && refContainer.current) {
      const { top, height } = refContainer.current.getBoundingClientRect();
      refContainer.current.style.height = (document.body.clientHeight - (height / 2) - top - 50) + 'px';
    }
  }, []);

  return (
    <div className='flex flex-col items-center justify-around' ref={refContainer}>
      <Preloader size='lg' />
    </div>
  );
};

export default memo(AsyncLoaded);
